import type { CookieOptions } from "#app";

export const useRawCookie = (
  name: string,
  options?: CookieOptions<string | null | undefined>
) => {
  return useCookie<string | null | undefined>(name, {
    encode: (value) => value ?? "",
    decode: (value) => value ?? "",
    ...(options as any),
  });
};
