import axios from "axios";

export default defineNuxtPlugin(() => {
  const ibgeApi = axios.create({
    baseURL: "https://servicodados.ibge.gov.br/api/v1",
  });

  return {
    provide: {
      ibgeApi,
    },
  };
});
