import { default as indexgUuDRxBjKYMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/cases-de-sucesso/index.vue?macro=true";
import { default as conta_45bancariaybkCUpL0UlMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/completar-cadastro/conta-bancaria.vue?macro=true";
import { default as dados_45documentoBY7QGRxj3IMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/completar-cadastro/dados-documento.vue?macro=true";
import { default as dados_45patrimoniaisVUzDFTnBgDMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/completar-cadastro/dados-patrimoniais.vue?macro=true";
import { default as declaracoesFDcZQmgq4UMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/completar-cadastro/declaracoes.vue?macro=true";
import { default as documentositUNcutOYPMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/completar-cadastro/documentos.vue?macro=true";
import { default as enderecoPnQ1BAaGfFMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/completar-cadastro/endereco.vue?macro=true";
import { default as index3tFZTtYXOYMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/completar-cadastro/index.vue?macro=true";
import { default as informacoes_45pessoais74mdf2nzgWMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/completar-cadastro/informacoes-pessoais.vue?macro=true";
import { default as _91partner_93AHMfgZn7QlMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/crie-sua-conta/[partner].vue?macro=true";
import { default as indexgRaXhKYoEpMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/index.vue?macro=true";
import { default as confirmacao21TsjGiEOgMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/investir/[operationId]/confirmacao.vue?macro=true";
import { default as detalhesn66VnUO6cPMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/investir/[operationId]/detalhes.vue?macro=true";
import { default as enquadramentoWmnwzKcUEQMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/investir/[operationId]/enquadramento.vue?macro=true";
import { default as indexHTpbCU4sGpMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/investir/[operationId]/index.vue?macro=true";
import { default as confirmar_45contaD6AoKHftJGMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/investir/[operationId]/inicio/confirmar-conta.vue?macro=true";
import { default as criar_45contaKSOuzhAR2SMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/investir/[operationId]/inicio/criar-conta.vue?macro=true";
import { default as indexke4aXNEc4OMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/investir/[operationId]/inicio/index.vue?macro=true";
import { default as iniciowZ7jYPiKLSMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/investir/[operationId]/inicio.vue?macro=true";
import { default as _91indicationCode_93sMkCskQbUKMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/novo-investidor/[indicationCode].vue?macro=true";
import { default as _91operationId_93qz7g7g0SBYMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/oportunidades/[operationId].vue?macro=true";
import { default as finalizadasJX2C01jjPsMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/oportunidades/finalizadas.vue?macro=true";
import { default as historicoXw4WNrxeIjMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/oportunidades/historico.vue?macro=true";
import { default as indexnvqHiLesUDMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/oportunidades/index.vue?macro=true";
import { default as _91operationId_93sxfo2ncRaUMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/oportunidades/parceiro/[operationId].vue?macro=true";
import { default as index7mAKczBGdIMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/originadoras/index.vue?macro=true";
import { default as _91slug_93pyO2qdfnWEMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/ajuda/[slug].vue?macro=true";
import { default as indexBDXfP1ySHwMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/ajuda/index.vue?macro=true";
import { default as _91operationId_93eR6o8xOhsSMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/detalhes-investimento/[operationId].vue?macro=true";
import { default as indexysi1xDJK0dMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/index.vue?macro=true";
import { default as index666CmFjjPJMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/indicacao/index.vue?macro=true";
import { default as indexEcKBX3zZvbMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/ir/index.vue?macro=true";
import { default as indexMSTWnHgfvfMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/pendentes/index.vue?macro=true";
import { default as indexPNTfvA2WbLMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/alterar-email/index.vue?macro=true";
import { default as indexdAeY5EL78CMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/alterar-senha/index.vue?macro=true";
import { default as index4qRUjhwitwMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/alterar-telefone/index.vue?macro=true";
import { default as adicionarH4uQZfe2OGMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/chave-pix/adicionar.vue?macro=true";
import { default as indexYPrAlEpOzwMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/chave-pix/index.vue?macro=true";
import { default as editarPhUlAlzuKCMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/[id]/editar.vue?macro=true";
import { default as adicionara8YDDG30NtMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/adicionar.vue?macro=true";
import { default as indexKLzGvCfILRMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/index.vue?macro=true";
import { default as indexNbSP33rDZNMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/dados-patrimoniais/index.vue?macro=true";
import { default as indexZxPUj9oAFUMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/dados-pessoais/index.vue?macro=true";
import { default as indexb3xZG0h2ewMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/desativar-conta/index.vue?macro=true";
import { default as indexYnV3BZgLIPMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/documentos/index.vue?macro=true";
import { default as indexXkwBPUZZDBMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/endereco/index.vue?macro=true";
import { default as indexkIBw4RiAKfMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/index.vue?macro=true";
import { default as _91assetSymbol_93z9iUVLGnELMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/wallet/[assetSymbol].vue?macro=true";
import { default as deposito3ac69FjtT1Meta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/wallet/deposito.vue?macro=true";
import { default as indexBCuwLrIwrcMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/wallet/index.vue?macro=true";
import { default as resgateXmmebFC0hEMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/wallet/resgate.vue?macro=true";
import { default as indexoS4q2MZ23RMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/parceiros/index.vue?macro=true";
import { default as indexEtFb49XlNGMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/politica-de-privacidade/index.vue?macro=true";
import { default as indexQJpXelADbQMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/quem-somos/index.vue?macro=true";
import { default as indexGxJrPJTgmqMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/remocao-de-conta/index.vue?macro=true";
import { default as _91signupCode_93zvKmRMhBhdMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/seja-investidor/[signupCode].vue?macro=true";
import { default as indexe8Uxn9yDFfMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/termos-de-uso/index.vue?macro=true";
import { default as indexiZHAKKVbMPMeta } from "/codebuild/output/src1483661895/src/hcp-web-platform/pages/tokenizacao/index.vue?macro=true";
export default [
  {
    name: indexgUuDRxBjKYMeta?.name ?? "cases-de-sucesso",
    path: indexgUuDRxBjKYMeta?.path ?? "/cases-de-sucesso",
    meta: indexgUuDRxBjKYMeta || {},
    alias: indexgUuDRxBjKYMeta?.alias || [],
    redirect: indexgUuDRxBjKYMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/cases-de-sucesso/index.vue").then(m => m.default || m)
  },
  {
    name: conta_45bancariaybkCUpL0UlMeta?.name ?? "completar-cadastro-conta-bancaria",
    path: conta_45bancariaybkCUpL0UlMeta?.path ?? "/completar-cadastro/conta-bancaria",
    meta: conta_45bancariaybkCUpL0UlMeta || {},
    alias: conta_45bancariaybkCUpL0UlMeta?.alias || [],
    redirect: conta_45bancariaybkCUpL0UlMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/completar-cadastro/conta-bancaria.vue").then(m => m.default || m)
  },
  {
    name: dados_45documentoBY7QGRxj3IMeta?.name ?? "completar-cadastro-dados-documento",
    path: dados_45documentoBY7QGRxj3IMeta?.path ?? "/completar-cadastro/dados-documento",
    meta: dados_45documentoBY7QGRxj3IMeta || {},
    alias: dados_45documentoBY7QGRxj3IMeta?.alias || [],
    redirect: dados_45documentoBY7QGRxj3IMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/completar-cadastro/dados-documento.vue").then(m => m.default || m)
  },
  {
    name: dados_45patrimoniaisVUzDFTnBgDMeta?.name ?? "completar-cadastro-dados-patrimoniais",
    path: dados_45patrimoniaisVUzDFTnBgDMeta?.path ?? "/completar-cadastro/dados-patrimoniais",
    meta: dados_45patrimoniaisVUzDFTnBgDMeta || {},
    alias: dados_45patrimoniaisVUzDFTnBgDMeta?.alias || [],
    redirect: dados_45patrimoniaisVUzDFTnBgDMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/completar-cadastro/dados-patrimoniais.vue").then(m => m.default || m)
  },
  {
    name: declaracoesFDcZQmgq4UMeta?.name ?? "completar-cadastro-declaracoes",
    path: declaracoesFDcZQmgq4UMeta?.path ?? "/completar-cadastro/declaracoes",
    meta: declaracoesFDcZQmgq4UMeta || {},
    alias: declaracoesFDcZQmgq4UMeta?.alias || [],
    redirect: declaracoesFDcZQmgq4UMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/completar-cadastro/declaracoes.vue").then(m => m.default || m)
  },
  {
    name: documentositUNcutOYPMeta?.name ?? "completar-cadastro-documentos",
    path: documentositUNcutOYPMeta?.path ?? "/completar-cadastro/documentos",
    meta: documentositUNcutOYPMeta || {},
    alias: documentositUNcutOYPMeta?.alias || [],
    redirect: documentositUNcutOYPMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/completar-cadastro/documentos.vue").then(m => m.default || m)
  },
  {
    name: enderecoPnQ1BAaGfFMeta?.name ?? "completar-cadastro-endereco",
    path: enderecoPnQ1BAaGfFMeta?.path ?? "/completar-cadastro/endereco",
    meta: enderecoPnQ1BAaGfFMeta || {},
    alias: enderecoPnQ1BAaGfFMeta?.alias || [],
    redirect: enderecoPnQ1BAaGfFMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/completar-cadastro/endereco.vue").then(m => m.default || m)
  },
  {
    name: index3tFZTtYXOYMeta?.name ?? "completar-cadastro",
    path: index3tFZTtYXOYMeta?.path ?? "/completar-cadastro",
    meta: index3tFZTtYXOYMeta || {},
    alias: index3tFZTtYXOYMeta?.alias || [],
    redirect: index3tFZTtYXOYMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/completar-cadastro/index.vue").then(m => m.default || m)
  },
  {
    name: informacoes_45pessoais74mdf2nzgWMeta?.name ?? "completar-cadastro-informacoes-pessoais",
    path: informacoes_45pessoais74mdf2nzgWMeta?.path ?? "/completar-cadastro/informacoes-pessoais",
    meta: informacoes_45pessoais74mdf2nzgWMeta || {},
    alias: informacoes_45pessoais74mdf2nzgWMeta?.alias || [],
    redirect: informacoes_45pessoais74mdf2nzgWMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/completar-cadastro/informacoes-pessoais.vue").then(m => m.default || m)
  },
  {
    name: _91partner_93AHMfgZn7QlMeta?.name ?? "crie-sua-conta-partner",
    path: _91partner_93AHMfgZn7QlMeta?.path ?? "/crie-sua-conta/:partner()",
    meta: _91partner_93AHMfgZn7QlMeta || {},
    alias: _91partner_93AHMfgZn7QlMeta?.alias || [],
    redirect: _91partner_93AHMfgZn7QlMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/crie-sua-conta/[partner].vue").then(m => m.default || m)
  },
  {
    name: indexgRaXhKYoEpMeta?.name ?? "index",
    path: indexgRaXhKYoEpMeta?.path ?? "/",
    meta: indexgRaXhKYoEpMeta || {},
    alias: indexgRaXhKYoEpMeta?.alias || [],
    redirect: indexgRaXhKYoEpMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/index.vue").then(m => m.default || m)
  },
  {
    name: confirmacao21TsjGiEOgMeta?.name ?? "investir-operationId-confirmacao",
    path: confirmacao21TsjGiEOgMeta?.path ?? "/investir/:operationId()/confirmacao",
    meta: confirmacao21TsjGiEOgMeta || {},
    alias: confirmacao21TsjGiEOgMeta?.alias || [],
    redirect: confirmacao21TsjGiEOgMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/investir/[operationId]/confirmacao.vue").then(m => m.default || m)
  },
  {
    name: detalhesn66VnUO6cPMeta?.name ?? "investir-operationId-detalhes",
    path: detalhesn66VnUO6cPMeta?.path ?? "/investir/:operationId()/detalhes",
    meta: detalhesn66VnUO6cPMeta || {},
    alias: detalhesn66VnUO6cPMeta?.alias || [],
    redirect: detalhesn66VnUO6cPMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/investir/[operationId]/detalhes.vue").then(m => m.default || m)
  },
  {
    name: enquadramentoWmnwzKcUEQMeta?.name ?? "investir-operationId-enquadramento",
    path: enquadramentoWmnwzKcUEQMeta?.path ?? "/investir/:operationId()/enquadramento",
    meta: enquadramentoWmnwzKcUEQMeta || {},
    alias: enquadramentoWmnwzKcUEQMeta?.alias || [],
    redirect: enquadramentoWmnwzKcUEQMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/investir/[operationId]/enquadramento.vue").then(m => m.default || m)
  },
  {
    name: indexHTpbCU4sGpMeta?.name ?? "investir-operationId",
    path: indexHTpbCU4sGpMeta?.path ?? "/investir/:operationId()",
    meta: indexHTpbCU4sGpMeta || {},
    alias: indexHTpbCU4sGpMeta?.alias || [],
    redirect: indexHTpbCU4sGpMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/investir/[operationId]/index.vue").then(m => m.default || m)
  },
  {
    name: iniciowZ7jYPiKLSMeta?.name ?? undefined,
    path: iniciowZ7jYPiKLSMeta?.path ?? "/investir/:operationId()/inicio",
    meta: iniciowZ7jYPiKLSMeta || {},
    alias: iniciowZ7jYPiKLSMeta?.alias || [],
    redirect: iniciowZ7jYPiKLSMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/investir/[operationId]/inicio.vue").then(m => m.default || m),
    children: [
  {
    name: confirmar_45contaD6AoKHftJGMeta?.name ?? "investir-operationId-inicio-confirmar-conta",
    path: confirmar_45contaD6AoKHftJGMeta?.path ?? "confirmar-conta",
    meta: confirmar_45contaD6AoKHftJGMeta || {},
    alias: confirmar_45contaD6AoKHftJGMeta?.alias || [],
    redirect: confirmar_45contaD6AoKHftJGMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/investir/[operationId]/inicio/confirmar-conta.vue").then(m => m.default || m)
  },
  {
    name: criar_45contaKSOuzhAR2SMeta?.name ?? "investir-operationId-inicio-criar-conta",
    path: criar_45contaKSOuzhAR2SMeta?.path ?? "criar-conta",
    meta: criar_45contaKSOuzhAR2SMeta || {},
    alias: criar_45contaKSOuzhAR2SMeta?.alias || [],
    redirect: criar_45contaKSOuzhAR2SMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/investir/[operationId]/inicio/criar-conta.vue").then(m => m.default || m)
  },
  {
    name: indexke4aXNEc4OMeta?.name ?? "investir-operationId-inicio",
    path: indexke4aXNEc4OMeta?.path ?? "",
    meta: indexke4aXNEc4OMeta || {},
    alias: indexke4aXNEc4OMeta?.alias || [],
    redirect: indexke4aXNEc4OMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/investir/[operationId]/inicio/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91indicationCode_93sMkCskQbUKMeta?.name ?? "novo-investidor-indicationCode",
    path: _91indicationCode_93sMkCskQbUKMeta?.path ?? "/novo-investidor/:indicationCode()",
    meta: _91indicationCode_93sMkCskQbUKMeta || {},
    alias: _91indicationCode_93sMkCskQbUKMeta?.alias || [],
    redirect: _91indicationCode_93sMkCskQbUKMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/novo-investidor/[indicationCode].vue").then(m => m.default || m)
  },
  {
    name: _91operationId_93qz7g7g0SBYMeta?.name ?? "oportunidades-operationId",
    path: _91operationId_93qz7g7g0SBYMeta?.path ?? "/oportunidades/:operationId()",
    meta: _91operationId_93qz7g7g0SBYMeta || {},
    alias: _91operationId_93qz7g7g0SBYMeta?.alias || [],
    redirect: _91operationId_93qz7g7g0SBYMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/oportunidades/[operationId].vue").then(m => m.default || m)
  },
  {
    name: finalizadasJX2C01jjPsMeta?.name ?? "oportunidades-finalizadas",
    path: finalizadasJX2C01jjPsMeta?.path ?? "/oportunidades/finalizadas",
    meta: finalizadasJX2C01jjPsMeta || {},
    alias: finalizadasJX2C01jjPsMeta?.alias || [],
    redirect: finalizadasJX2C01jjPsMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/oportunidades/finalizadas.vue").then(m => m.default || m)
  },
  {
    name: historicoXw4WNrxeIjMeta?.name ?? "oportunidades-historico",
    path: historicoXw4WNrxeIjMeta?.path ?? "/oportunidades/historico",
    meta: historicoXw4WNrxeIjMeta || {},
    alias: historicoXw4WNrxeIjMeta?.alias || [],
    redirect: historicoXw4WNrxeIjMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/oportunidades/historico.vue").then(m => m.default || m)
  },
  {
    name: indexnvqHiLesUDMeta?.name ?? "oportunidades",
    path: indexnvqHiLesUDMeta?.path ?? "/oportunidades",
    meta: indexnvqHiLesUDMeta || {},
    alias: indexnvqHiLesUDMeta?.alias || [],
    redirect: indexnvqHiLesUDMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/oportunidades/index.vue").then(m => m.default || m)
  },
  {
    name: _91operationId_93sxfo2ncRaUMeta?.name ?? "oportunidades-parceiro-operationId",
    path: _91operationId_93sxfo2ncRaUMeta?.path ?? "/oportunidades/parceiro/:operationId()",
    meta: _91operationId_93sxfo2ncRaUMeta || {},
    alias: _91operationId_93sxfo2ncRaUMeta?.alias || [],
    redirect: _91operationId_93sxfo2ncRaUMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/oportunidades/parceiro/[operationId].vue").then(m => m.default || m)
  },
  {
    name: index7mAKczBGdIMeta?.name ?? "originadoras",
    path: index7mAKczBGdIMeta?.path ?? "/originadoras",
    meta: index7mAKczBGdIMeta || {},
    alias: index7mAKczBGdIMeta?.alias || [],
    redirect: index7mAKczBGdIMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/originadoras/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pyO2qdfnWEMeta?.name ?? "painel-ajuda-slug",
    path: _91slug_93pyO2qdfnWEMeta?.path ?? "/painel/ajuda/:slug()",
    meta: _91slug_93pyO2qdfnWEMeta || {},
    alias: _91slug_93pyO2qdfnWEMeta?.alias || [],
    redirect: _91slug_93pyO2qdfnWEMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/ajuda/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexBDXfP1ySHwMeta?.name ?? "painel-ajuda",
    path: indexBDXfP1ySHwMeta?.path ?? "/painel/ajuda",
    meta: indexBDXfP1ySHwMeta || {},
    alias: indexBDXfP1ySHwMeta?.alias || [],
    redirect: indexBDXfP1ySHwMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/ajuda/index.vue").then(m => m.default || m)
  },
  {
    name: _91operationId_93eR6o8xOhsSMeta?.name ?? "painel-detalhes-investimento-operationId",
    path: _91operationId_93eR6o8xOhsSMeta?.path ?? "/painel/detalhes-investimento/:operationId()",
    meta: _91operationId_93eR6o8xOhsSMeta || {},
    alias: _91operationId_93eR6o8xOhsSMeta?.alias || [],
    redirect: _91operationId_93eR6o8xOhsSMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/detalhes-investimento/[operationId].vue").then(m => m.default || m)
  },
  {
    name: indexysi1xDJK0dMeta?.name ?? "painel",
    path: indexysi1xDJK0dMeta?.path ?? "/painel",
    meta: indexysi1xDJK0dMeta || {},
    alias: indexysi1xDJK0dMeta?.alias || [],
    redirect: indexysi1xDJK0dMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/index.vue").then(m => m.default || m)
  },
  {
    name: index666CmFjjPJMeta?.name ?? "painel-indicacao",
    path: index666CmFjjPJMeta?.path ?? "/painel/indicacao",
    meta: index666CmFjjPJMeta || {},
    alias: index666CmFjjPJMeta?.alias || [],
    redirect: index666CmFjjPJMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/indicacao/index.vue").then(m => m.default || m)
  },
  {
    name: indexEcKBX3zZvbMeta?.name ?? "painel-ir",
    path: indexEcKBX3zZvbMeta?.path ?? "/painel/ir",
    meta: indexEcKBX3zZvbMeta || {},
    alias: indexEcKBX3zZvbMeta?.alias || [],
    redirect: indexEcKBX3zZvbMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/ir/index.vue").then(m => m.default || m)
  },
  {
    name: indexMSTWnHgfvfMeta?.name ?? "painel-pendentes",
    path: indexMSTWnHgfvfMeta?.path ?? "/painel/pendentes",
    meta: indexMSTWnHgfvfMeta || {},
    alias: indexMSTWnHgfvfMeta?.alias || [],
    redirect: indexMSTWnHgfvfMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/pendentes/index.vue").then(m => m.default || m)
  },
  {
    name: indexPNTfvA2WbLMeta?.name ?? "painel-perfil-alterar-email",
    path: indexPNTfvA2WbLMeta?.path ?? "/painel/perfil/alterar-email",
    meta: indexPNTfvA2WbLMeta || {},
    alias: indexPNTfvA2WbLMeta?.alias || [],
    redirect: indexPNTfvA2WbLMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/alterar-email/index.vue").then(m => m.default || m)
  },
  {
    name: indexdAeY5EL78CMeta?.name ?? "painel-perfil-alterar-senha",
    path: indexdAeY5EL78CMeta?.path ?? "/painel/perfil/alterar-senha",
    meta: indexdAeY5EL78CMeta || {},
    alias: indexdAeY5EL78CMeta?.alias || [],
    redirect: indexdAeY5EL78CMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/alterar-senha/index.vue").then(m => m.default || m)
  },
  {
    name: index4qRUjhwitwMeta?.name ?? "painel-perfil-alterar-telefone",
    path: index4qRUjhwitwMeta?.path ?? "/painel/perfil/alterar-telefone",
    meta: index4qRUjhwitwMeta || {},
    alias: index4qRUjhwitwMeta?.alias || [],
    redirect: index4qRUjhwitwMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/alterar-telefone/index.vue").then(m => m.default || m)
  },
  {
    name: adicionarH4uQZfe2OGMeta?.name ?? "painel-perfil-chave-pix-adicionar",
    path: adicionarH4uQZfe2OGMeta?.path ?? "/painel/perfil/chave-pix/adicionar",
    meta: adicionarH4uQZfe2OGMeta || {},
    alias: adicionarH4uQZfe2OGMeta?.alias || [],
    redirect: adicionarH4uQZfe2OGMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/chave-pix/adicionar.vue").then(m => m.default || m)
  },
  {
    name: indexYPrAlEpOzwMeta?.name ?? "painel-perfil-chave-pix",
    path: indexYPrAlEpOzwMeta?.path ?? "/painel/perfil/chave-pix",
    meta: indexYPrAlEpOzwMeta || {},
    alias: indexYPrAlEpOzwMeta?.alias || [],
    redirect: indexYPrAlEpOzwMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/chave-pix/index.vue").then(m => m.default || m)
  },
  {
    name: editarPhUlAlzuKCMeta?.name ?? "painel-perfil-dados-bancarios-id-editar",
    path: editarPhUlAlzuKCMeta?.path ?? "/painel/perfil/dados-bancarios/:id()/editar",
    meta: editarPhUlAlzuKCMeta || {},
    alias: editarPhUlAlzuKCMeta?.alias || [],
    redirect: editarPhUlAlzuKCMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/[id]/editar.vue").then(m => m.default || m)
  },
  {
    name: adicionara8YDDG30NtMeta?.name ?? "painel-perfil-dados-bancarios-adicionar",
    path: adicionara8YDDG30NtMeta?.path ?? "/painel/perfil/dados-bancarios/adicionar",
    meta: adicionara8YDDG30NtMeta || {},
    alias: adicionara8YDDG30NtMeta?.alias || [],
    redirect: adicionara8YDDG30NtMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/adicionar.vue").then(m => m.default || m)
  },
  {
    name: indexKLzGvCfILRMeta?.name ?? "painel-perfil-dados-bancarios",
    path: indexKLzGvCfILRMeta?.path ?? "/painel/perfil/dados-bancarios",
    meta: indexKLzGvCfILRMeta || {},
    alias: indexKLzGvCfILRMeta?.alias || [],
    redirect: indexKLzGvCfILRMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/index.vue").then(m => m.default || m)
  },
  {
    name: indexNbSP33rDZNMeta?.name ?? "painel-perfil-dados-patrimoniais",
    path: indexNbSP33rDZNMeta?.path ?? "/painel/perfil/dados-patrimoniais",
    meta: indexNbSP33rDZNMeta || {},
    alias: indexNbSP33rDZNMeta?.alias || [],
    redirect: indexNbSP33rDZNMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/dados-patrimoniais/index.vue").then(m => m.default || m)
  },
  {
    name: indexZxPUj9oAFUMeta?.name ?? "painel-perfil-dados-pessoais",
    path: indexZxPUj9oAFUMeta?.path ?? "/painel/perfil/dados-pessoais",
    meta: indexZxPUj9oAFUMeta || {},
    alias: indexZxPUj9oAFUMeta?.alias || [],
    redirect: indexZxPUj9oAFUMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/dados-pessoais/index.vue").then(m => m.default || m)
  },
  {
    name: indexb3xZG0h2ewMeta?.name ?? "painel-perfil-desativar-conta",
    path: indexb3xZG0h2ewMeta?.path ?? "/painel/perfil/desativar-conta",
    meta: indexb3xZG0h2ewMeta || {},
    alias: indexb3xZG0h2ewMeta?.alias || [],
    redirect: indexb3xZG0h2ewMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/desativar-conta/index.vue").then(m => m.default || m)
  },
  {
    name: indexYnV3BZgLIPMeta?.name ?? "painel-perfil-documentos",
    path: indexYnV3BZgLIPMeta?.path ?? "/painel/perfil/documentos",
    meta: indexYnV3BZgLIPMeta || {},
    alias: indexYnV3BZgLIPMeta?.alias || [],
    redirect: indexYnV3BZgLIPMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/documentos/index.vue").then(m => m.default || m)
  },
  {
    name: indexXkwBPUZZDBMeta?.name ?? "painel-perfil-endereco",
    path: indexXkwBPUZZDBMeta?.path ?? "/painel/perfil/endereco",
    meta: indexXkwBPUZZDBMeta || {},
    alias: indexXkwBPUZZDBMeta?.alias || [],
    redirect: indexXkwBPUZZDBMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/endereco/index.vue").then(m => m.default || m)
  },
  {
    name: indexkIBw4RiAKfMeta?.name ?? "painel-perfil",
    path: indexkIBw4RiAKfMeta?.path ?? "/painel/perfil",
    meta: indexkIBw4RiAKfMeta || {},
    alias: indexkIBw4RiAKfMeta?.alias || [],
    redirect: indexkIBw4RiAKfMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/perfil/index.vue").then(m => m.default || m)
  },
  {
    name: _91assetSymbol_93z9iUVLGnELMeta?.name ?? "painel-wallet-assetSymbol",
    path: _91assetSymbol_93z9iUVLGnELMeta?.path ?? "/painel/wallet/:assetSymbol()",
    meta: _91assetSymbol_93z9iUVLGnELMeta || {},
    alias: _91assetSymbol_93z9iUVLGnELMeta?.alias || [],
    redirect: _91assetSymbol_93z9iUVLGnELMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/wallet/[assetSymbol].vue").then(m => m.default || m)
  },
  {
    name: deposito3ac69FjtT1Meta?.name ?? "painel-wallet-deposito",
    path: deposito3ac69FjtT1Meta?.path ?? "/painel/wallet/deposito",
    meta: deposito3ac69FjtT1Meta || {},
    alias: deposito3ac69FjtT1Meta?.alias || [],
    redirect: deposito3ac69FjtT1Meta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/wallet/deposito.vue").then(m => m.default || m)
  },
  {
    name: indexBCuwLrIwrcMeta?.name ?? "painel-wallet",
    path: indexBCuwLrIwrcMeta?.path ?? "/painel/wallet",
    meta: indexBCuwLrIwrcMeta || {},
    alias: indexBCuwLrIwrcMeta?.alias || [],
    redirect: indexBCuwLrIwrcMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/wallet/index.vue").then(m => m.default || m)
  },
  {
    name: resgateXmmebFC0hEMeta?.name ?? "painel-wallet-resgate",
    path: resgateXmmebFC0hEMeta?.path ?? "/painel/wallet/resgate",
    meta: resgateXmmebFC0hEMeta || {},
    alias: resgateXmmebFC0hEMeta?.alias || [],
    redirect: resgateXmmebFC0hEMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/painel/wallet/resgate.vue").then(m => m.default || m)
  },
  {
    name: indexoS4q2MZ23RMeta?.name ?? "parceiros",
    path: indexoS4q2MZ23RMeta?.path ?? "/parceiros",
    meta: indexoS4q2MZ23RMeta || {},
    alias: indexoS4q2MZ23RMeta?.alias || [],
    redirect: indexoS4q2MZ23RMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/parceiros/index.vue").then(m => m.default || m)
  },
  {
    name: indexEtFb49XlNGMeta?.name ?? "politica-de-privacidade",
    path: indexEtFb49XlNGMeta?.path ?? "/politica-de-privacidade",
    meta: indexEtFb49XlNGMeta || {},
    alias: indexEtFb49XlNGMeta?.alias || [],
    redirect: indexEtFb49XlNGMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/politica-de-privacidade/index.vue").then(m => m.default || m)
  },
  {
    name: indexQJpXelADbQMeta?.name ?? "quem-somos",
    path: indexQJpXelADbQMeta?.path ?? "/quem-somos",
    meta: indexQJpXelADbQMeta || {},
    alias: indexQJpXelADbQMeta?.alias || [],
    redirect: indexQJpXelADbQMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/quem-somos/index.vue").then(m => m.default || m)
  },
  {
    name: indexGxJrPJTgmqMeta?.name ?? "remocao-de-conta",
    path: indexGxJrPJTgmqMeta?.path ?? "/remocao-de-conta",
    meta: indexGxJrPJTgmqMeta || {},
    alias: indexGxJrPJTgmqMeta?.alias || [],
    redirect: indexGxJrPJTgmqMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/remocao-de-conta/index.vue").then(m => m.default || m)
  },
  {
    name: _91signupCode_93zvKmRMhBhdMeta?.name ?? "seja-investidor-signupCode",
    path: _91signupCode_93zvKmRMhBhdMeta?.path ?? "/seja-investidor/:signupCode()",
    meta: _91signupCode_93zvKmRMhBhdMeta || {},
    alias: _91signupCode_93zvKmRMhBhdMeta?.alias || [],
    redirect: _91signupCode_93zvKmRMhBhdMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/seja-investidor/[signupCode].vue").then(m => m.default || m)
  },
  {
    name: indexe8Uxn9yDFfMeta?.name ?? "termos-de-uso",
    path: indexe8Uxn9yDFfMeta?.path ?? "/termos-de-uso",
    meta: indexe8Uxn9yDFfMeta || {},
    alias: indexe8Uxn9yDFfMeta?.alias || [],
    redirect: indexe8Uxn9yDFfMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/termos-de-uso/index.vue").then(m => m.default || m)
  },
  {
    name: indexiZHAKKVbMPMeta?.name ?? "tokenizacao",
    path: indexiZHAKKVbMPMeta?.path ?? "/tokenizacao",
    meta: indexiZHAKKVbMPMeta || {},
    alias: indexiZHAKKVbMPMeta?.alias || [],
    redirect: indexiZHAKKVbMPMeta?.redirect,
    component: () => import("/codebuild/output/src1483661895/src/hcp-web-platform/pages/tokenizacao/index.vue").then(m => m.default || m)
  }
]