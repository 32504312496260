export class ApiV3Error extends Error {
  public code: string | null = null;

  constructor(requestError: any) {
    const fields = requestError?.response?.data?.data;
    const message = fields?.message ?? "Unknown error";
    super(message);

    this.message = message;
    const code = fields?.code;
    if (typeof code === "string") {
      this.code = code;
    }
  }
}

export class UnauthenticatedError extends Error {
  constructor() {
    super("User not authenticated");
    this.name = "UnathenticatedError";

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}
