import type { RestAccount } from "~/composables/api/account/useAccount";

interface LoginBody {
  nid: string;
  password: string;
}

interface LoginResult {
  accessToken: string;
  refreshToken: string;
  refreshTokenExpiresIn: number;
  accessTokenExpiresIn: number;
  expiresIn: number;
  account: RestAccount;
}

export const login = async (body: LoginBody): Promise<LoginResult> => {
  const { $authApi } = useNuxtApp();

  const res = await $authApi.post<{ data: LoginResult }>("/login", body);
  return res.data.data;
};
