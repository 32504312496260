import { useAuthStore } from "@/store/auth";
import { makeAuthHeader } from "@/utils/auth/make-auth-header";
import { stringToBoolean } from "@/utils/string/string-to-boolean";
import axios, { AxiosError, type InternalAxiosRequestConfig } from "axios";
import { useSendClientErrorToSentry } from "~/composables/useSendClientErrorToSentry";

let isRefreshing = false;
let failedRequestsQueue: Array<{
  resolve: (token: string) => void;
  reject: (err: AxiosError) => void;
}> = [];

const makeCorsHeader = () => ({
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Headers": "*",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "DELETE, GET, POST, PATCH, PUT, OPTIONS",
  "X-Content-Type-Options": "nosniff",
});

const makeSecurityHeader = () => ({
  "Content-Security-Policy": "default-src https frame-ancestors 'none'",
  "Referrer-Policy": "same-origin",
  "X-Frame-Options": "DENY",
  "X-XSS-Protection": "1; mode=block",
  "X-Is-Webapp": "true",
});

const THIRTY_DAYS = 60 * 60 * 24 * 30;

export default defineNuxtPlugin(() => {
  const { $performTokenRefresh, $refreshLock } = useNuxtApp();
  const env = useRuntimeConfig();
  const cookieOptions = { path: "/", maxAge: THIRTY_DAYS };
  const tokenCookie = useCookie("token", cookieOptions);
  const signOut = useSignOut();

  const sendErrorToSentry = useSendClientErrorToSentry();

  const getDefaultHeaders = () => {
    return {
      ...makeCorsHeader(),
      ...makeSecurityHeader(),
      ...makeAuthHeader(),
      ...(stringToBoolean(env.public.isCrowdfunding)
        ? { "x-website-type": "crowdfunding" }
        : {}),
    };
  };

  const createAxiosClient = ({
    baseURL,
    headers,
  }: {
    baseURL: string;
    headers: any;
  }) => {
    const api = axios.create({
      baseURL,
      headers,
    });

    api.interceptors.request.use(async (config) => {
      await $refreshLock();

      const token = tokenCookie.value;
      const currentAuthHeader = api.defaults.headers.Authorization;

      if (token && !currentAuthHeader) {
        config.headers.Authorization = `${token}`;
      }

      return config;
    });

    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError<any>) => {
        sendErrorToSentry(error);

        if (error.response?.status === 401) {
          if (error.response?.data?.data?.message === "User not Authorized") {
            const originalConfig = error.config;

            if (!isRefreshing) {
              isRefreshing = true;
              $performTokenRefresh()
                .then((refreshTokenData) => {
                  const auth = useAuthStore();
                  auth.setAuthenticated(true);

                  const token = refreshTokenData?.accessToken ?? "";
                  api.defaults.headers.Authorization = token;

                  failedRequestsQueue.forEach((request) =>
                    request.resolve(token)
                  );
                  failedRequestsQueue = [];
                })
                .catch((err: any) => {
                  sendErrorToSentry(err);
                  signOut();
                  failedRequestsQueue.forEach((request) => request.reject(err));
                  failedRequestsQueue = [];
                })
                .finally(() => {
                  isRefreshing = false;
                });
            }

            return new Promise((resolve, reject) => {
              failedRequestsQueue.push({
                resolve: (token: string) => {
                  originalConfig!.headers.Authorization = token;
                  resolve(api(originalConfig as InternalAxiosRequestConfig));
                },
                reject: (err: AxiosError) => {
                  sendErrorToSentry(err);
                  reject(err);
                },
              });
            });
          } else {
            signOut();
          }
        }
        return Promise.reject(error);
      }
    );

    return api;
  };

  const api = createAxiosClient({
    baseURL: env.public.apiBaseURL as string,
    headers: getDefaultHeaders(),
  });
  const walletApi = createAxiosClient({
    baseURL: env.public.apiWalletBaseURL as string,
    headers: getDefaultHeaders(),
  });
  const operationV2Api = createAxiosClient({
    baseURL: env.public.apiOperationV2BaseURL as string,
    headers: getDefaultHeaders(),
  });
  const authApi = createAxiosClient({
    baseURL: env.public.authBaseURL as string,
    headers: { "Content-Type": "application/json" },
  });
  const rootApi = createAxiosClient({
    baseURL: env.public.apiRootURL as string,
    headers: getDefaultHeaders(),
  });

  return {
    provide: {
      api,
      walletApi,
      operationV2Api,
      authApi,
      rootApi,
    },
  };
});
